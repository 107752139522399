<template>
  <v-container>
    <v-card class="w-100 h-100" fluid>
      <v-card-title class="justify-center">
        {{
          this.$vuetify.lang.t(
            "$vuetify.main.redactorDashboard.redactors.title"
          )
        }}
      </v-card-title>
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12" offset-md="1" md="3">
            <v-dialog v-model="newRedactorForm" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                  {{
                    $vuetify.lang.t(
                      "$vuetify.main.redactorDashboard.redactors.createRedactor"
                    )
                  }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">
                    {{
                      $vuetify.lang.t(
                        "$vuetify.main.redactorDashboard.redactors.createRedactor"
                      )
                    }}
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="redactorForm" v-model="valid">
                      <v-row>
                        <v-text-field
                          :label="
                            $vuetify.lang.t(
                              `$vuetify.main.redactorDashboard.redactors.name`
                            )
                          "
                          v-model="redactorName"
                          :rules="redactorNameRules"
                          required
                        ></v-text-field>
                      </v-row>
                      <v-row class="mt-5">
                        <vue-phone-number-input
                          v-model="redactorPhone"
                          :loader="hasLoaderActive"
                          :error="hasErrorActive"
                          :default-country-code="defaultCountry"
                          :preferred-countries="countriesList"
                          show-code-on-list
                          required
                          @update="updatedData"
                          :translations="translations"
                          clearable
                        />
                      </v-row>
                      <v-row class="mt-5">
                        <v-text-field
                          label="E-mail*"
                          v-model="redactorEmail"
                          :rules="redactorEmailRules"
                          required
                        ></v-text-field>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeForm">
                    {{
                      $vuetify.lang.t(
                        "$vuetify.main.redactorDashboard.redactors.cancel"
                      )
                    }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="createNewRedactor">
                    {{
                      $vuetify.lang.t(
                        "$vuetify.main.redactorDashboard.redactors.accept"
                      )
                    }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="12" md="7">
            <v-card v-for="(redactor, i) in redactors" :key="i" class="mt-2">
              <v-card-title>{{ redactor.name }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <span>{{ redactor.phone }}</span>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span>{{ redactor.email }}</span>
                  </v-col>
                  <v-col cols="12" md="3" offset-md="1">
                    <v-btn small @click="deleteRedactor(redactor.id)">
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.redactorDashboard.redactors.delete"
                        )
                      }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "redactorsComponent",
  metaInfo() {
    return {
      title: "Платформа / Редактора",
    };
  },
  data() {
    return {
      newRedactorForm: false,
      valid: false,
      updateValue: "",
      defaultCountry: "",
      countriesList: ["UA", "RU", "BY", "MD"],
      hasLoaderActive: false,
      hasErrorActive: false,
      userData: {},
      redactors: {},
      redactorName: "",
      redactorEmail: "",
      redactorPhone: "",
      redactorNameRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientNameRules"
          ),
      ],
      redactorEmailRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientEmailRules1"
          ),
        (v) =>
          /.+@.+/.test(v) ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientEmailRules2"
          ),
      ],
    };
  },
  computed: {
    ...mapGetters({
      redactorsList: "user/getRedactorsList",
    }),
    translations() {
      return {
        countrySelectorLabel: this.$vuetify.lang.t(
          "$vuetify.view.signIn.countrySelectorLabel"
        ),
        countrySelectorError: this.$vuetify.lang.t(
          "$vuetify.view.signIn.countrySelectorError"
        ),
        phoneNumberLabel: this.$vuetify.lang.t(
          "$vuetify.view.signIn.phoneNumberLabel"
        ),
        example: this.$vuetify.lang.t("$vuetify.view.signIn.example"),
      };
    },
  },
  beforeMount() {
    this.$store.dispatch("user/getRedactorsList");
  },
  watch: {
    redactorsList: function () {
      this.loadRedactorsList();
    },
    userData() {
      let countryCode = this.userData.countryCode;
      let nationalNumber = this.userData.nationalNumber;
      switch (countryCode) {
        case "UA":
          if (nationalNumber) {
            if (Array.from(nationalNumber).length > 9) {
              this.hasErrorActive = true;
              this.userData.isValid = false;
            } else {
              this.hasErrorActive = false;
            }
          }
      }
    },
  },
  methods: {
    loadRedactorsList() {
      this.redactors = this.redactorsList;
    },
    validate() {
      this.$refs.redactorForm.validate();
      if (this.userData.isValid && this.valid) {
        return true;
      }
    },
    updatedData(data) {
      this.userData = data;
    },
    closeForm() {
      this.resetForm();
    },
    createNewRedactor() {
      if (this.validate()) {
        let payload = {
          phone: this.userData.formattedNumber,
          email: this.redactorEmail,
          redactor: { name: this.redactorName },
        };
        this.$store
          .dispatch("user/createNewRedactor", JSON.stringify(payload))
          .then((response) => {
            Vue.swal({
              target: document.getElementById("main"),
              text: response.data.message,
              icon: "success",
              timer: 4000,
              toast: true,
              position: "top-right",
              showConfirmButton: false,
            });
            this.resetForm();
          });
      }
    },
    resetForm() {
      this.newRedactorForm = false;
      this.redactorName = "";
      this.redactorEmail = "";
      this.redactorPhone = "";
    },

    deleteRedactor(id) {
      this.$store.dispatch("user/deleteRedactor", id).then(() => {
        this.newRedactorForm = false;
      });
    },
  },
};
</script>

<style scoped></style>
